.console-header {
  background: #cfd8dc;
  height: 20px; }

.console-button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  float: left;
  margin-left: 5px; }

.console-buttons {
  float: right;
  margin-right: 5px;
  margin-top: 5px; }

.btn-1 {
  background: #8bc34a; }

.btn-2 {
  background: #ffb74d; }

.btn-3 {
  background: #d32f2f; }
