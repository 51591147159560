.link-list {
  color: white;
  padding-top: 20px;
  padding-bottom: 20px; }

.link-list a {
  color: white;
  text-decoration: none;
  margin-right: 5px;
  margin-left: 5px; }

.link-list a:first-of-type {
  margin-left: 0; }
