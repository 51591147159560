.fragment {
  padding-left: 5%;
  padding-right: 5%; }

.fragment-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 160px;
  padding-bottom: 160px; }

p.headline {
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 10pt;
  opacity: 0.8; }

.fragment-container.home-component {
  padding: 0; }

.fragment-container.footer-component {
  padding: 0; }

.fragment-container.centered {
  max-width: 720px; }
