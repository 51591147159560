ul {
  list-style: none;
  color: #efebe9; }

.pretty-json {
  font-size: 14px; }

.row {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0; }

.json-code-key {
  color: #ffcc80; }

.json-code-value {
  color: #ff8080; }
