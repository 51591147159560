.project {
  margin-bottom: 10px; }

.project-highlight {
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block; }

.projects {
  margin-top: 20px; }
